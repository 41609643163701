@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "Gotham Regular";
		src: local("Gotham Regular"),
			url(./assets/fonts/GothamRegular.otf) format("otf");
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	ul,
	li,
	ol,
	p,
	a {
		@apply font-gotham;
	}
}

.loader {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 9999;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader img {
	max-width: 50px;
}

/* custom sty;ling for background */
@media (min-width: 1280px) and (max-width: 1300px) {
	.effect-1 svg {
		position: absolute;
		right: -40px;
	}

	.effect-2 svg {
		position: absolute;
		right: -380px;
	}
}

@media (min-width: 1536px) and (max-width: 1600px) {
	.effect-1 svg {
		position: absolute;
		right: 0px;
	}

	.effect-2 svg {
		position: absolute;
		right: -300px;
	}
}

.light-bg {
	background-image: url("./assets/images/light_bg.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-size: 100% 100%;
}

.dark-bg {
	background-image: url("./assets/images/dark_bg.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-size: 100% 100%;
}

/* styling for checkbox */
.check-box:checked ~ .check-1 {
	@apply block;
}
